import { CrmOption } from 'common-module/common';
import {
  crmGetUserName,
  CrmUser,
  CrmUserRoles,
  CrmUserTitle,
} from 'common-module/user';

import { StaffPosition } from './staff.position';
import { UserPermissions } from './user.permissions';

export type UserRole =
  | CrmUserRoles
  | 'staff'
  | 'customer'
  | 'attendance'
  | 'selfservice'
  | 'signature';

export const userStatuses = ['active', 'lapsing', 'lapsed'] as const;
export type UserStatus = (typeof userStatuses)[number];

export const userGenders = ['unspecified', 'male', 'female'] as const;
export const userGendersOptions: CrmOption[] = userGenders.map((value) => ({
  label: ['generic.gender', value].join('.'),
  value,
}));
export type UserGender = (typeof userGenders)[number];

export interface UserModel extends CrmUser {
  title?: CrmUserTitle;
  roles: Partial<Record<UserRole, boolean>>;
  company?: {
    name: string;
    id: string;
    vat: string;
    tax: string;
  };
  permissions?: {
    frontend?: UserPermissions;
  };
  meta?: {
    note?: string;
    position?: StaffPosition;
    hiddenStaff?: boolean;
    category?: string[];
    color?: string;
    jobTitle?: string;
    jobDescription?: string;
    verified?: boolean;
    gdpr?: boolean;
    patientNames?: string[];
    patientSpecies?: string[];
    patients?: string[];
  };
  membership: string[];
  status?: UserStatus;
  note?: string;
}

export const getFullName = (
  user?: UserModel,
  skipCompany = false,
  skipTitles = false,
) => {
  if (!user) {
    return '-';
  }

  if (user.company?.name && !skipCompany) {
    return user.company.name;
  }

  const name = crmGetUserName(user, { title: !skipTitles });

  return name.length ? name : user.email ?? '-';
};

export const mapUserOptions = (
  users: UserModel[],
): CrmOption<string, UserModel>[] =>
  users.map((user) => ({
    label: getFullName(user),
    value: user._id,
    data: user,
  }));
